.dj-u-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-125 {
  padding-bottom: 12.5rem !important;
}

.pt-210 {
  padding-top: 21rem !important;
}

.pt-250 {
  padding-top: 25rem !important;
}

.mt-30 {
  margin-top: 3rem !important;
}

.text-center {
  text-align: center !important;
}

.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none !important;
}

.display-xl-none {
  @include more-than(xl) {
    display: none !important;
  }
}

.display-xl-inline {
  @include more-than(xl) {
    display: inline !important;
  }
}
