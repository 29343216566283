.dj-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 6.6rem);
  padding: (12.2rem + $padding-header) 0 10rem 0;
  color: $color-text-light;

  &--desktop {
    display: none;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    position: relative;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 4.5rem;
    }
  }

  &__link,
  &__link:link,
  &__link:visited {
    font-weight: 300;
    font-size: 2rem;
    font-family: $font-primary;
    text-decoration: none;
    color: currentColor;
  }

  &__link-decoration {
    position: absolute;
    bottom: -8px;
    opacity: 0;
    width: 100%;
    height: 1px;
    border-radius: 1rem;
    background-color: $color-primary;
    transition: bottom 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 3.2rem;
    height: 3.2rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    &--messenger {
      margin-bottom: -2px;
      background-image: url("../assets/images/icon-messenger.svg");
    }

    &--instagram {
      background-image: url("../assets/images/icon-instagram.svg");
    }

    @include more-than(xl) {
      color: $color-secondary;
      transition: color 0.2s ease;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      &:hover {
        color: $color-primary;
      }

      &__socials {
        padding-top: 0.9rem;
      }
    }
  }

  @include more-than(xl) {
    height: auto;
    padding: 0;
    color: $color-text-dark;

    &--desktop {
      display: block;
    }

    &__list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 5rem;
    }

    &__list-item:not(:last-child) {
      margin-bottom: 0;
    }

    &__link-decoration--active {
      bottom: 0;
      opacity: 1;
    }

    &__icon {
      color: $color-secondary;
      transition: color 0.2s ease;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      &:hover {
        color: $color-primary;
      }
    }

    &__socials {
      padding-top: 0.9rem;
    }
  }
}
