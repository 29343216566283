.dj-project {
  &__title {
    font-size: 3rem;
  }

  &__type {
    padding: 0.5rem 0 5rem 0;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.2;
    font-family: $font-primary;
  }

  &__desc {
    max-width: 60rem;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.2;
    font-family: $font-primary;

    & > p {
      margin: 0 0 1.5rem 0;
    }
  }

  @include more-than(lg) {
    display: flex;
    gap: 10rem;

    &__title {
      margin-top: -1.6rem;
      font-size: 4.5rem;
      line-height: 0.9;
    }

    &__type {
      padding: 2rem 0 0 0;
    }

    &__desc {
      & > p {
        margin: 0 0 4rem 0;
      }
    }
  }

  @include more-than(xl) {
    padding-left: 4rem;

    &__title {
      margin-top: -2.5rem;
      font-size: 6rem;
    }

    &__type {
      font-size: 2.1rem;
    }

    &__desc {
      font-size: 2.1rem;
    }
  }

  @include more-than(xxl) {
    gap: 21rem;

    &__desc {
      max-width: 67rem;
    }
  }
}
