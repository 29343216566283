.dj-hero {
  text-align: center;

  & > * {
    text-align: initial;
  }

  &__heading {
    max-width: 29.5rem;
  }

  &__text {
    margin-top: 5.5rem;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: $font-secondary;
  }

  &__button {
    margin-top: 7rem;
    margin-bottom: 4rem;
    font-weight: 300;
    font-size: 2rem;
    line-height: 1;
  }

  &__bottom {
    padding-top: 7rem;
  }

  &__image-cont {
    width: 4.9rem;
    height: 4.7rem;
    margin-left: auto;
    overflow: hidden;
    animation: scale-pulse 2.5s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-1-cont {
    position: absolute;
    top: -16.8rem;
    left: calc(50% + 3rem);
    z-index: 150;
    width: 6rem;
    height: 25.5rem;
    overflow: hidden;
    animation: translate-y-pulse 3s infinite ease-in-out;

    &--shifted-up {
      top: -19.8rem;
    }
  }

  &__decoration-1-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-7-cont {
    position: absolute;
    top: 23rem;
    right: calc(50% - 41rem);
    display: none;
    width: 9.3rem;
    height: 12.8rem;
    overflow: hidden;
    animation: spin 20s infinite linear;
  }

  &__decoration-7-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-6-cont {
    position: absolute;
    bottom: -8rem;
    left: calc(50% - 14.8rem);
    z-index: 50;
    width: 5.3rem;
    height: 26rem;
    overflow: hidden;
    animation: translate-y-pulse 3s infinite ease-in-out;
  }

  &__decoration-6-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include more-than(lg) {
    &__heading {
      width: 77rem;
      max-width: none;
    }

    &__image-cont {
      margin-right: auto;
      margin-left: 0;
    }

    &__decoration-1-cont {
      top: -13rem;
      left: 35%;

      &--shifted-up {
        top: -19.8rem;
      }
    }

    &__image-cont {
      width: 8.7rem;
      height: 8.3rem;
    }

    &__decoration-6-cont {
      right: calc(50% - 41rem);
      bottom: -10rem;
      left: unset;
      width: 5.75rem;
      height: 28rem;
    }

    &__decoration-7-cont {
      display: block;
    }
  }

  @include more-than(xl) {
    &__image-cont {
      position: absolute;
      bottom: 30%;
      left: calc(50% - 60rem);
      width: 8.7rem;
      height: 8.3rem;
    }

    &__decoration-7-cont {
      top: 27rem;
      right: calc(50% - 59rem);
    }
  }
}
