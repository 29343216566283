.dj-logo {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &__image-cont {
    width: 1.9rem;
    height: 3.7rem;
    margin-right: 1.1rem;
    overflow: hidden;

    @include more-than(xl) {
      width: 2.9rem;
    }
  }

  &__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    width: 20rem;
    margin: -8px 0 0 0;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 1.05;
    font-family: $font-primary;
    color: $color-text-dark;

    @include more-than(xl) {
      width: 30rem;
      margin: 0;
      font-size: 2.2rem;
      line-height: 0.9;
    }

    &--inverted {
      color: $color-text-light;
    }
  }

  &__bold-text {
    display: inline-block;
    padding-left: 1px;
    font-weight: 800;
    letter-spacing: 0.5px;
    font-size: 1.1rem;

    @include more-than(xl) {
      font-size: 1.6rem;
    }
  }
}
