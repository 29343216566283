@use "sass:map";

.dj-l-container {
  width: 100%;
  padding: 0 3.5rem;
  margin: 0 auto;

  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @include more-than(lg) {
    width: map.get($container-max-widths, "lg");
  }

  @include more-than(xl) {
    width: map.get($container-max-widths, "xl");
  }

  @include more-than(xxl) {
    width: map.get($container-max-widths, "xxl");
  }
}
