@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes scale-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-pulse-soft {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes translate-y-pulse {
  0% {
    transform: translateY(0) scale(1);
  }

  50% {
    transform: translateY(1.5rem) scale(1.05);
  }

  100% {
    transform: translateY(0) scale(1);
  }
}
