.dj-cookie-bar {
  position: fixed;
  right: 50%;
  bottom: 50%;
  z-index: 175;
  opacity: 0;
  width: 32rem;
  height: 40rem;
  padding: 1.4rem 3rem 2.6rem 3rem;
  font-family: $font-primary;
  border-radius: 2.2rem;
  background-color: $color-primary;
  box-shadow: 1rem 1rem 3.6rem rgba($color: #000, $alpha: 0.75);
  transform: translate(50%, 50%);
  transition: opacity 0.3s ease;

  &--visible {
    opacity: 1;
  }

  &__img {
    width: 15.3rem;
    height: 15.3rem;
    margin: 0 auto 1.2rem auto;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  &__heading {
    margin-bottom: 2rem;
    font-weight: 800;
    font-size: 2.3rem;
    color: $color-text-light;
  }

  &__paragraph {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    line-height: 1.5;
    color: $color-text-light;
  }

  &__btn-group {
    display: flex;
    gap: 2.2rem;
  }

  &__btn {
    padding: 0.4rem 1.4rem;
    font-weight: 800;
    font-size: 1.2rem;
    border-radius: 3rem;
    cursor: pointer;
    transition:
      color 0.3s ease,
      background-color 0.3s ease;

    &--reject {
      color: $color-text-light;
      border: 2px solid $color-text-light;
      background-color: $color-primary;

      &:hover {
        color: $color-primary;
        background-color: $color-text-light;
      }
    }

    &--accept {
      color: $color-secondary;
      border: 2px solid $color-secondary;
      background-color: $color-primary;

      &:hover {
        color: $color-primary;
        background-color: $color-secondary;
      }
    }
  }

  @include more-than(xl) {
    right: 10.2rem;
    bottom: 9.3rem;
    transform: none;

    &__btn {
      padding: 0.4rem 1.8rem;
    }
  }
}
