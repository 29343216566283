.dj-button {
  display: inline-block;
  padding: 1rem 2.9rem;
  font-weight: 800;
  font-size: 1.8rem;
  line-height: 1.2;
  font-family: $font-primary;
  text-decoration: none;
  color: currentColor;
  border-radius: 4.2rem;
  letter-spacing: 0.5px;

  &--round {
    width: 3.8rem;
    height: 3.8rem;
    padding: 0;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-secondary;
  }

  &--primary {
    color: $color-text-light;
    background-color: $color-secondary;

    @include hover-colors($color-primary, $color-text-light);
  }

  &--burger {
    background-image: url("../assets/images/icon-hamburger.svg");
  }

  &--message {
    background-image: url("../assets/images/icon-message.svg");
    transition: background-image 0.2s ease;
  }

  &--cross {
    background-image: url("../assets/images/icon-cross.svg");
  }

  &--fixed {
    position: fixed;
    right: 3.7rem;
    bottom: 4.6rem;
    z-index: 150;
  }

  @include more-than(lg) {
    font-size: 2rem;

    &--message:hover {
      background-image: url("../assets/images/icon-message-blue.svg");
    }
  }

  @include more-than(xl) {
    &--message {
      width: 7.5rem;
      height: 7.5rem;
      background-size: 3.8rem 3.3rem;
    }

    &--fixed {
      position: fixed;
      right: 10.2rem;
      bottom: 9.3rem;
    }
  }
}
