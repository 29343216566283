.dj-l-section {
  position: relative;
  padding: 10rem 0;
  color: $color-text-dark;
  background-color: $color-text-light;

  &--inverted {
    color: $color-text-light;
    background-color: $color-primary;
  }

  &--full {
    height: calc(100vh - 7.8rem);
  }

  &--huge-p-top {
    padding-top: 25rem;
  }

  &__anchor {
    position: absolute;
    top: -7.8rem;
    left: 0;
  }

  @include more-than(lg) {
    &--huge-p-top {
      padding: 20rem 0;
    }
  }

  @include more-than(xl) {
    padding: 25rem 0;

    &--full {
      height: calc(100vh - 8.1rem);
    }

    &__anchor {
      top: -8.1rem;
    }
  }
}
