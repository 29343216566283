.dj-workflow {
  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 11rem;
  }

  &__step-img {
    position: relative;
    width: 12rem;
    height: 12rem;
    padding: 0;
    margin-top: 3.6rem;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-secondary;

    &--people {
      background-image: url("../assets/images/icon-people.svg");

      & > .dj-workflow__step-egg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 19.8rem;
        height: 19rem;
        transform: translate(-47.5%, -48.5%);
      }
    }

    &--brain {
      background-image: url("../assets/images/icon-brain.svg");

      & > .dj-workflow__step-egg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 17.8rem;
        height: 18.3rem;
        transform: translate(-49%, -53%);
      }
    }

    &--desktop {
      background-image: url("../assets/images/icon-desktop.svg");

      & > .dj-workflow__step-egg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 17.8rem;
        height: 17rem;
        transform: translate(-53%, -51%);
      }
    }

    &--gift {
      background-image: url("../assets/images/icon-gift.svg");

      & > .dj-workflow__step-egg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 13.7rem;
        height: 15rem;
        transform: translate(-50%, -46%);
      }
    }
  }

  &__step-desc {
    margin-top: 3.4rem;
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: $font-secondary;
    text-align: center;
    letter-spacing: 1px;
  }

  @include more-than(lg) {
    display: flex;
    flex-wrap: wrap;

    &__step {
      flex-basis: 50%;
    }
  }

  @include more-than(xl) {
    flex-wrap: nowrap;

    &__step {
      flex-basis: 25%;
    }

    &__step-img {
      margin-top: 7.5rem;
    }

    &__step-desc {
      margin-top: 7.5rem;
    }
  }
}
