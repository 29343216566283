@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Regular.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Bold.woff2") format("woff2"),
    url("../assets/fonts/Raleway-Bold.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Light.woff2") format("woff2"),
    url("../assets/fonts/Raleway-Light.woff") format("woff");
  font-display: fallback;
}
