.dj-copyright {
  padding: 25.5rem 0 1rem 0;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.3;
  font-family: $font-primary;
  text-align: center;

  &__company-identification-number {
    display: inline-block;
    padding-top: 5px;
  }

  &__separator {
    display: none;
  }

  @include more-than(xl) {
    padding-bottom: 2rem;
    font-size: 1.5rem;

    &__separator {
      display: inline-block;
      padding: 0 2px;
    }
  }
}
