.dj-l-navigation {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  padding-top: $padding-header;
  background-color: $color-primary;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;

  &--opened {
    transform: translateX(0);
  }

  @include more-than(xl) {
    display: none;
  }
}
