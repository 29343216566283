.dj-designer {
  &__image-cont {
    position: relative;
    width: 18.5rem;
    height: 18.5rem;
    margin: 0 auto;

    &--desktop {
      display: none;
    }

    & > .dj-designer__egg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28rem;
      height: 29rem;
      transform: translate(-48.6%, -53%);
    }
  }

  &__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__decoration-3-m-cont {
    position: absolute;
    top: 6.6rem;
    left: calc(50% - 15.4rem);
    width: 18.4rem;
    height: 19.1rem;
    overflow: hidden;
  }

  &__decoration-3-m-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-3-d-cont {
    position: absolute;
    top: 15.6rem;
    right: calc(50% - 50.4rem);
    display: none;
    width: 28rem;
    height: 19.4rem;
    overflow: hidden;
  }

  &__decoration-3-d-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-4-m-cont {
    width: 30.5rem;
    height: 11.7rem;
    margin: 6.8rem auto 0 auto;
    overflow: hidden;
  }

  &__decoration-4-m-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-4-d-cont {
    display: none;
    width: 5.9rem;
    height: 37.4rem;
    overflow: hidden;
  }

  &__decoration-4-d-el {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text {
    width: 30.5rem;
    margin: -3.5rem auto 0 auto;
    font-weight: 300;
    font-size: 1.9rem;
    line-height: 1.4;
    font-family: $font-primary;

    &--desktop {
      display: none;
      width: 68.5rem;
    }

    & > strong {
      display: inline-block;
      padding-bottom: 2.5rem;
      font-size: 2.2rem;
    }
  }

  &__desktop-layout {
    display: none;
  }

  @include more-than(md) {
    &__decoration-3-m-cont {
      left: calc(50% - 26rem);
    }
  }

  @include more-than(lg) {
    &__decoration-3-m-cont {
      left: calc(50% - 26rem);
    }

    &__decoration-3-m-cont {
      display: none;
    }

    &__decoration-3-d-cont {
      display: block;
    }

    &__decoration-4-m-cont {
      display: none;
    }

    &__decoration-4-d-cont {
      display: block;
      align-self: flex-end;
    }

    &__text {
      display: none;
    }

    &__image-cont {
      display: none;
    }

    &__image-cont--desktop {
      display: block;
      margin: 0 0 8.3rem 4rem;
    }

    &__text {
      display: none;
    }

    &__text--desktop {
      display: block;
      margin: -1.5rem 0 0 4rem;
    }

    &__desktop-layout {
      display: flex;
      justify-content: center;
      padding-right: 20rem;
    }
  }

  @include more-than(xxl) {
    &__desktop-layout {
      padding-right: 40rem;
    }
  }
}
