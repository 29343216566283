.dj-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__image-cont {
    position: relative;
    flex-grow: 1;
    flex-basis: 100%;
    overflow: hidden;

    @include more-than(lg) {
      flex-basis: 50%;
    }
  }

  &__image-el {
    width: 100%;
    height: 101%;
    margin-top: -2px;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    font-weight: 800;
    font-family: $font-primary;
    text-align: center;
    text-decoration: none;
    color: $color-text-light;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.8s ease, backdrop-filter 0.8s;
    gap: 3rem;
  }

  &__project-title {
    opacity: 0;
    margin: 0;
    font-size: 2rem;
    letter-spacing: 0.5px;
    transition: opacity 0.8s ease;
  }

  &__project-desc {
    opacity: 0;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: $font-secondary;
    transition: opacity 0.8s ease;
  }

  @include more-than(lg) {
    &__project-title {
      font-size: 3.5rem;
    }

    &__project-desc {
      font-size: 2.1rem;
    }

    &__image-cont:hover {
      & > .dj-gallery__overlay--black {
        background-color: rgba(0, 0, 0, 0.8);
      }

      & > .dj-gallery__overlay--black-soft {
        background-color: rgba(0, 0, 0, 0.5);
      }

      & > .dj-gallery__overlay--black-softer {
        background-color: rgba(0, 0, 0, 0.3);
      }

      & > .dj-gallery__overlay--frosted {
        // backdrop-filter: blur(4px);
      }

      & .dj-gallery__project-title {
        opacity: 1;
      }

      & .dj-gallery__project-desc {
        opacity: 1;
      }
    }
  }
}
