.dj-l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: $padding-header 0;
  background-color: $color-text-light;
  transition: box-shadow 0.2s ease-in;

  &--shadow {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  }
}
