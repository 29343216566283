.dj-h {
  margin: 0;
  font-family: $font-primary;

  &--primary {
    font-size: 2.8rem;
    line-height: 1.2;
  }

  &--secondary {
    letter-spacing: 0.5px;
    font-size: 3rem;
  }

  &--tertiary {
    letter-spacing: 0.5px;
    font-weight: 800;
    font-size: 2.1rem;
  }

  &--dark {
    color: $color-text-dark;
  }

  &--light {
    color: $color-text-light;
  }

  @include more-than(lg) {
    &--primary {
      font-size: 5rem;
    }

    &--secondary {
      font-size: 3.5rem;
    }

    &--tertiary {
      font-size: 2.5rem;
    }

    &--project-name {
      font-size: 6rem;
    }
  }
}
