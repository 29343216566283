.dj-contact {
  padding-top: 2.2rem;

  &__column {
    padding-top: 9.3rem;
  }

  &__list {
    padding: 5.8rem 0 0 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1.7rem;
    }
  }

  &__icon {
    width: 2.8rem;
    height: 2.8rem;
    background-position: center center;
    background-repeat: no-repeat;

    &--envelope {
      background-image: url("../assets/images/icon-envelope.svg");
    }

    &--mobile {
      background-image: url("../assets/images/icon-mobile.svg");
    }

    &--messenger {
      background-image: url("../assets/images/icon-messenger.svg");
    }

    &--instagram {
      background-image: url("../assets/images/icon-instagram.svg");
    }
  }

  &__link,
  &__link:link,
  &__link:visited {
    margin-left: 1.8rem;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: $font-secondary;
    text-decoration: none;
    color: currentColor;

    @include more-than(xl) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__arrow-cont {
    position: absolute;
    bottom: 9rem;
    left: 50%;
    width: 7.5rem;
    height: 7.5rem;
    padding: 0;
    border-radius: 50%;
    transform: translate(-50%, 17rem);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &--visible {
      transform: translate(-50%, 0);
    }
  }

  &__arrow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("../assets/images/icon-arrow-up.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-secondary;
    transition: background-image 0.2s ease;
    animation: scale-pulse-soft 1s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &__decoration-5-cont {
    position: absolute;
    top: 6.6rem;
    left: 3.6rem;
    width: 7.5rem;
    height: 8rem;
    animation: spin 15s linear infinite;
  }

  &__decoration-5-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__decoration-8-cont {
    position: absolute;
    bottom: -3.6rem;
    left: calc(50% - 50rem);
    display: none;
    width: 3.7rem;
    height: 24.8rem;
    animation: translate-y-pulse 3s infinite ease-in-out;
  }

  &__decoration-8-el {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include more-than(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include more-than(lg) {
    flex-direction: row;
    justify-content: space-between;

    &__arrow:hover {
      background-image: url("../assets/images/icon-arrow-up-blue.svg");
    }
  }

  @include more-than(xl) {
    justify-content: center;
    gap: 20rem;

    &__link,
    &__link:link,
    &__link:visited {
      font-size: 2rem;

      &:hover {
        text-decoration: underline;
      }
    }

    &__decoration-5-cont {
      top: 20rem;
      left: calc(50% - 65rem);
    }

    &__decoration-8-cont {
      display: block;
    }
  }

  @include more-than(xxl) {
    gap: 20rem;
  }
}
