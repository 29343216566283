$color-primary: #1d2937;
$color-secondary: #de8686;
$color-beige: #79796f;

$color-text-dark: #1d2937;
$color-text-light: #fff;

$font-primary: raleway, sans-serif;
$font-secondary: "Open Sans", sans-serif;

$padding-header: 2rem;
